
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class OrderSuccess extends Vue {
  mounted () {
    const { query: { id } } = this.$route;
    if (!id) {
      this.back();
    } else {
      this.id = id as string;
    }
  }
  id = '';
  back () {
    this.$router.replace('/service');
  }
}
